import LoginPage from "../containers/auth/index";
import NotFoundPage from "../containers/404/index";
import DashboardPage from "../containers/dashboard/index";
import UserDetailsPage from "../containers/users/details";
import UserListsPage from "../containers/users/index";
import AdminListsPage from "../containers/admin_users/index";
import CardCatalogPage from "../containers/catalog/card";
import CompleteTasksPage from "../containers/dashboard/completeTask";
import MarketplaceClicksPage from "../containers/dashboard/marketplaceClick";
import MerchantIconsPage from "../containers/merchant-icons/card";
import TagsPage from "../containers/tags/index";
import MerchantListingPage from "../containers/merchant-listing/index";
import CouncilTaxesPage from "../containers/uk-council-taxes/index";
import Reports from "../containers/reports/index";
import PartnerCodes from "../containers/partner-codes/index";
import SpinTheWheel from "../containers/spin-the-wheel/index";
import UserVoucherData from "../containers/user-voucher/index";
import subcodesPage from "../containers/partner-codes/subcodes";
import subcodeUsedPage from "../containers/partner-codes/subcode-used";
import cashbackRetailers from "../containers/cashback-retailers";
import UserCoinGiftLogs from "../containers/user-gift-logs";
import MoneyPlusPayments from "../containers/dashboard/moneyplusPayments";
import UniqueLoginPage from "../containers/unique-login-data";
import MoneyplusInfoPage from "../containers/moneyplus-due-data";

// routes for declare new pages
const routes = [
  { path: "/login", component: LoginPage, exact: true, oldPath: "/login" },
  {
    path: "/admin/dashboard",
    component: DashboardPage,
    exact: true,
    oldPath: "/admin/dashboard",
  },
  {
    path: "/admin/users",
    component: UserListsPage,
    exact: true,
    oldPath: "/admin/users",
  },
  {
    path: "/admin/users/:userId",
    component: UserDetailsPage,
    exact: true,
    oldPath: "/admin/users:userId",
  },
  {
    path: "/admin/card-catalog",
    component: CardCatalogPage,
    exact: true,
    oldPath: "/admin/card-catalog",
  },
  {
    path: "/admin/merchant-icons",
    component: MerchantIconsPage,
    exact: true,
    oldPath: "/admin/merchant-icons",
  },
  {
    path: "/admin/dashboard/completetask",
    component: CompleteTasksPage,
    exact: true,
    oldPath: "/admin/dashboard/completetask",
  },
  {
    path: "/admin/admins",
    component: AdminListsPage,
    exact: true,
    oldPath: "/admin/admins",
  },
  {
    path: "/admin/dashboard/marketplaceClicks",
    component: MarketplaceClicksPage,
    exact: true,
    oldPath: "/admin/dashboard/marketplaceClicks",
  },
  {
    path: "/admin/tags",
    component: TagsPage,
    exact: true,
    oldPath: "/admin/tags",
  },
  {
    path: "/admin/merchant-listing",
    component: MerchantListingPage,
    exact: true,
    oldPath: "/admin/merchant-listing",
  },
  {
    path: "/admin/council-tax",
    component: CouncilTaxesPage,
    exact: true,
    oldPath: "/admin/council-tax",
  },
  {
    path: "/admin/reports",
    component: Reports,
    exact: true,
    oldPath: "/admin/reports",
  },

  {
    path: "/admin/partner-codes",
    component: PartnerCodes,
    exact: true,
    oldPath: "/admin/partner-codes",
  },
  {
    path: "/admin/partner-codes/subcodes/:id/:partnerName",
    component: subcodesPage,
    exact: true,
    oldPath: "/admin/partner-codes/subcodes/:id/:partnerName",
  },

  {
    path: "/admin/partner-codes/subcode-used/:id/:partnerName",
    component: subcodeUsedPage,
    exact: true,
    oldPath: "/admin/partner-codes/subcodes-used/:id/:partnerName",
  },

  {
    path: "/admin/spin-the-wheel",
    component: SpinTheWheel,
    exact: true,
    oldPath: "/admin/spin-the-wheel",
  },

  {
    path: "/admin/user-voucher",
    component: UserVoucherData,
    exact: true,
    oldPath: "/admin/user-voucher",
  },

  {
    path: "/admin/cashback-retailers",
    component: cashbackRetailers,
    exact: true,
    oldPath: "/admin/cashback-retailers",
  },

  {
    path: "/admin/users/:userId/gift-logs",
    component: UserCoinGiftLogs,
    exact: true,
  },

  {
    path: "/admin/dashboard/moneyplus",
    component: MoneyPlusPayments,
    exact: true,
  },

  {
    path: "/admin/unique-login-data",
    component: UniqueLoginPage,
    exact: true,
    oldPath: "/admin/unique-login-data",
  },

  {
    path: "/admin/moneyplus-users",
    component: MoneyplusInfoPage,
    exact: true,
    oldPath: "/admin/moneyplus-users",
  },

  // {
  //   path: "/admin/notification-broadcast",
  //   component: cashbackRetailers,
  //   exact: true,
  //   oldPath: "/admin/notification-broadcast",
  // },

  { path: "/", component: LoginPage, exact: true, oldPath: "/" },
  { component: NotFoundPage },
];

export default routes;
