import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

// It's shows the card catalog info
class index extends Component {
  componentDidMount() {
    this.props.loadUniqueLoginHistory();
  }

  // Render the card info in table formate
  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_text, record) => (
        <p
          style={{
            width: 300,
          }}
        >
          {record.date || ""}
        </p>
      ),
    },
    {
      title: "User Count",
      dataIndex: "users_number",
      key: "users_number",
      render: (_text, record) => <p>{record.users_number || ""}</p>,
    },
  ];

  render() {
    const { uniqueLoginHistory } = this.props;
    let results = [];
    if (uniqueLoginHistory && uniqueLoginHistory.length > 0) {
      for (var i = 0; i < uniqueLoginHistory.length; i++) {
        var key = `unique-login-data-${uniqueLoginHistory[i].admin_unique_login_data_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...uniqueLoginHistory[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Unique User Login History!</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    uniqueLoginHistory: state.uniqueLoginHistory,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
