import { Button, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
//import moment from "moment";
import "./index.less";

const { Header, Content, Footer, Sider } = Layout;

class index extends Component {
  // This function is used to logout user from the app and redirect user to the login page.
  logout = () => {
    this.props.logout();
    this.props.history.push("/login");
  };

  resetUesrIndex = () => {
    this.props.updateUserCurrentPage(1);
  };

  render() {
    const { location } = this.props;
    var pathName = location?.pathname?.replace("/admin/", "") || "";
    pathName = pathName.split("/")[0];

    return (
      <Layout className={`layout ${this.props.className}`}>
        <Sider>
          <Header className="header">
            <div className="logo">
              <img
                src="images/logo.png"
                alt="SuperFi logo"
                className="logo-img"
              />
            </div>
          </Header>
          <Menu
            mode="inline"
            defaultSelectedKeys={["/"]}
            selectedKeys={[pathName]}
          >
            <Menu.Item key="dashboard" active={"true"}>
              <NavLink to="/admin/dashboard" onClick={this.resetUesrIndex}>
                Dashboard
              </NavLink>
            </Menu.Item>
            <Menu.Item key="users">
              <NavLink to="/admin/users">Users</NavLink>
            </Menu.Item>
            <Menu.Item key="admins">
              <NavLink to="/admin/admins" onClick={this.resetUesrIndex}>
                Admins
              </NavLink>
            </Menu.Item>
            <Menu.Item key="card-catalog">
              <NavLink to="/admin/card-catalog" onClick={this.resetUesrIndex}>
                Card data
              </NavLink>
            </Menu.Item>
            <Menu.Item key="merchant-icons">
              <NavLink to="/admin/merchant-icons" onClick={this.resetUesrIndex}>
                Merchants
              </NavLink>
            </Menu.Item>

            <Menu.Item key="tags">
              <NavLink to="/admin/tags" onClick={this.resetUesrIndex}>
                Tags
              </NavLink>
            </Menu.Item>

            <Menu.Item key="merchant-listing">
              <NavLink
                to="/admin/merchant-listing"
                onClick={this.resetUesrIndex}
              >
                Merchant Listing
              </NavLink>
            </Menu.Item>

            <Menu.Item key="council-tax">
              <NavLink to="/admin/council-tax" onClick={this.resetUesrIndex}>
                UK Council Taxes
              </NavLink>
            </Menu.Item>

            <Menu.Item key="reports">
              <NavLink to="/admin/reports" onClick={this.resetUesrIndex}>
                Reports
              </NavLink>
            </Menu.Item>

            <Menu.Item key="partner-codes">
              <NavLink to="/admin/partner-codes" onClick={this.resetUesrIndex}>
                Partner codes
              </NavLink>
            </Menu.Item>

            <Menu.Item key="spin-the-wheel">
              <NavLink to="/admin/spin-the-wheel" onClick={this.resetUesrIndex}>
                Spin the wheel
              </NavLink>
            </Menu.Item>

            <Menu.Item key="user-voucher">
              <NavLink to="/admin/user-voucher" onClick={this.resetUesrIndex}>
                User Vouchers
              </NavLink>
            </Menu.Item>

            <Menu.Item key="cashback-retailers">
              <NavLink
                to="/admin/cashback-retailers"
                onClick={this.resetUesrIndex}
              >
                Cashback Retailers
              </NavLink>
            </Menu.Item>

            <Menu.Item key="unique-login-data">
              <NavLink
                to="/admin/unique-login-data"
                onClick={this.resetUesrIndex}
              >
                Unique Login History
              </NavLink>
            </Menu.Item>
          </Menu>

          <Button
            className="btn-logout"
            onClick={() => {
              this.logout();
            }}
          >
            Log out
          </Button>
        </Sider>
        <Layout className="site-layout">
          <Content className="site-layout-background content-container">
            {this.props.children}
          </Content>
          <Footer className="footer-container">
            {/* <span className="copy-text">
              &copy; {moment().format("YYYY")} SuperFi All Rights Reserved.
            </span> */}
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
